import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import Layout from "../components/layout";
import luceroLogo from "../assets/images/website-icon.png";
import banner from "../assets/images/banner.jpg";

class Success extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.gtag("event", "conversion", { send_to: "AW-978917701/vGrgCMubxZQBEMWy5NID" });
  }
  
  render() {  
    const { children } = this.props;  
    return (
      <Layout>
        <Helmet>
          <title>Contacto - Plasticos Lucero</title>
          <meta name="description" content="envio formulario exitoso" />
        </Helmet>

        <div id="main" className="alt">
          <section id="banner" className="major" style={{ backgroundImage: `url(${banner})` }}>
            <div className="inner">
              <header className="major">
                <div>
                  <img src={luceroLogo} alt="Plastico Lucero" style={{ height: 100 }} />
                </div>
                <h1>&nbsp;Gracias!&nbsp;</h1>
              </header>
              <div className="content">
                <p>
                  Nos pondremos en contacto lo más rápido posible.<br/><br/>
                  <Link to="/bazar" className="button next">
                    &nbsp;SEGUÍ VIENDO NUESTROS PRODUCTOS&nbsp;
                  </Link>
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Success;
